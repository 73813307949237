@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@100;200;300;400;500;600;700;800;900;1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    overflow-x: hidden;
}

body {
    font-family: 'Roboto Flex', sans-serif;
}